<template>
  <div class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
    <div class="px-4 py-5 sm:p-6">
      <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div class="sm:col-span-6">
          <label for="data" class="block text-sm font-medium text-gray-700">
            Data
          </label>
          <div class="mt-1">
            <textarea v-model="data" id="data" name="data" rows="6" class="resize-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" placeholder="Fill in data to see the hash" />
          </div>
        </div>

        <div class="sm:col-span-6">
          <label for="hash" class="block text-sm font-medium text-gray-700">
            Hash
          </label>
          <div class="mt-1 flex rounded-md shadow-sm">
            <input v-model="hash" disabled type="text" name="hash" id="hash" class="font-mono truncate flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
          </div>
          <p class="mt-2 text-sm text-gray-500">Hash length is {{ hash.length }} characters</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const shajs = require('sha.js')

export default {
  name: 'Hash',
  data: () => ({
    data: '',
    hash: ''
  }),
  watch: {
    data: {
      handler: function (value) {
        this.hash = shajs('sha256').update(value).digest('hex')
      },
      immediate: true
    }
  }
}
</script>
