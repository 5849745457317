<template>
  <div class="py-16 overflow-hidden lg:py-24 lg:pb-4">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
      <div class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div class="relative">
          <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
            Hashing data
          </h3>
          <p class="mt-3 text-lg text-gray-500">
            Hashen van data betekent dat je een formule toepast op de data en dat deze formule vervolgens een reeks random karakters genereert. Hier staan belangrijke eigenschappen van hashen van data.
          </p>

          <dl class="mt-10 space-y-10">
            <div v-for="item in hashFeatures" :key="item.id" class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                </div>
                <p class="ml-16 text-lg leading-6 font-medium text-gray-900">{{ item.name }}</p>
              </dt>
              <dd class="mt-2 ml-16 text-base text-gray-500">
                {{ item.description }}
              </dd>
            </div>
          </dl>
        </div>

        <div class="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
          <Hash class="relative mx-auto w-11/12" />
        </div>
      </div>

      <div class="relative mt-12 sm:mt-16 lg:mt-24">
        <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div class="lg:col-start-2">
            <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              A single block
            </h3>
            <p class="mt-3 text-lg text-gray-500">
              Een block is hetzelfde als de data van het vorige voorbeeld. Alleen is de data nu opgedeeld in een Block, Nonce en de data.
            </p>

            <dl class="mt-10 space-y-10">
              <div v-for="item in blockFeatures" :key="item.id" class="relative">
                <dt>
                  <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p class="ml-16 text-lg leading-6 font-medium text-gray-900">{{ item.name }}</p>
                </dt>
                <dd class="mt-2 ml-16 text-base text-gray-500">
                  {{ item.description }}
                </dd>
              </div>
            </dl>
          </div>

          <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
            <Block class="relative mx-auto w-11/12" />
          </div>
        </div>
      </div>

      <hr class="border border-b mt-24" />

      <div class="mt-12 sm:mt-16 lg:mt-24">
        <div class="flex justify-between items-center">
          <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
            Blockchain
          </h3>
          <div class="w-32">
            <label for="nonce" class="block text-sm font-medium text-gray-700">
              Hash Length
            </label>
            <div class="mt-1 flex rounded-md shadow-sm">
              <input v-model="difficulty" type="number" min="1" name="difficulty" id="difficulty" class="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-64 min-w-0 rounded-md sm:text-sm border-gray-300" />
            </div>
          </div>
        </div>
        <div class="flex flex-col h-full flex-grow justify-start">
          <Blockchain :difficulty="difficulty" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BadgeCheckIcon, DuplicateIcon, HashtagIcon, ArrowRightIcon, FingerPrintIcon, KeyIcon } from '@heroicons/vue/outline'

import Hash from '@/components/Hash.vue'
import Block from '@/components/Block.vue'
import Blockchain from '@/components/Blockchain.vue'

const hashFeatures = [
  {
    id: 1,
    name: 'Elke keer hetzelfde',
    description:
      'Belangrijk is dat elke keer dat je een hash berekent, dat er elke keer dezelfde hash uitkomt voor dezelfde data.',
    icon: DuplicateIcon
  },
  {
    id: 2,
    name: 'Maar één kant op',
    description:
      'Een hash kan je alleen maar één kant op maken. Als je data hebt kan je de hash berekenen, maar je kan niet meer deze data krijgen als je de hash hebt.',
    icon: ArrowRightIcon
  },
  {
    id: 3,
    name: 'Unieke hash voor de data',
    description:
      'Ook al voeg je maar 1 letter toe, dan is de hash compleet anders. Hierdoor is de hash een soort vingerafdruk voor je data.',
    icon: FingerPrintIcon
  }
]
const blockFeatures = [
  {
    id: 1,
    name: 'Valid block',
    description:
      'Het blok is enkel valide wanneer de hash begint met 4 nullen.',
    icon: BadgeCheckIcon
  },
  {
    id: 2,
    name: 'Eigen nummer',
    description:
      'Elk blok heeft zijn eigen nummer of te weten waar in het rijtje de blok zich straks zal zitten.',
    icon: HashtagIcon
  },
  {
    id: 3,
    name: 'Nonce',
    description:
      'Het nummer is uniek en staat vast, de data die bepaal jij, maar hoe zorg je nu dat de hash begint met 4 nullen? Die doe je door te minen. Je probeert dan nummer 0 tot oneindig uit en checkt de hash voor de eerste 4 cijfers.',
    icon: KeyIcon
  }
]

export default {
  components: { Hash, Block, Blockchain },
  data: () => ({
    difficulty: 4
  }),
  setup() {
    return {
      hashFeatures,
      blockFeatures,
    }
  },
}
</script>
