<template>
  <div class="flex flex-col flex-1 blockchain-block shadow rounded-lg divide-y divide-gray-200" :class="prevHash.startsWith('0'.repeat(this.difficulty)) && hash.startsWith('0'.repeat(this.difficulty)) ? 'bg-green-50' : 'bg-red-100'">
    <div class="px-4 py-5 sm:p-6">
      <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div class="sm:col-span-6">
          <label for="block" class="block text-sm font-medium text-gray-700">
            Block
          </label>
          <div class="mt-1 flex rounded-md shadow-sm">
            <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
              #
            </span>
            <input disabled :value="block" type="number" min="0" name="block" id="block" class="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
          </div>
        </div>

        <div class="sm:col-span-6">
          <label for="nonce" class="block text-sm font-medium text-gray-700">
            Nonce
          </label>
          <div class="mt-1 flex rounded-md shadow-sm">
            <input v-model="nonce" type="number" min="0" name="nonce" id="nonce" class="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
          </div>
        </div>

        <div class="sm:col-span-6">
          <label for="data" class="block text-sm font-medium text-gray-700">
            Data
          </label>
          <div class="mt-1">
            <textarea v-model="data" id="data" name="data" rows="3" class="resize-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" />
          </div>
        </div>

        <div class="sm:col-span-6">
          <label for="prevHash" class="block text-sm font-medium text-gray-700">
            Previous hash
          </label>
          <div class="mt-1 flex rounded-md shadow-sm">
            <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
              <CheckIcon v-if="prevHash.startsWith('0'.repeat(this.difficulty))" class="-ml-1 mr-2 h-5 w-5 text-green-600" />
              <XIcon v-else class="-ml-1 mr-2 h-5 w-5 text-red-600" />
              <span class="font-mono">{{ prevHash.slice(0, difficulty) }}</span>
            </span>
            <input :value="prevHash.substring(difficulty)" disabled type="text" name="prevHash" id="prevHash" class="flex-1 font-mono focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 truncate" />
          </div>
        </div>

        <div class="sm:col-span-6">
          <label for="hash" class="block text-sm font-medium text-gray-700">
            Hash
          </label>
          <div class="mt-1 flex rounded-md shadow-sm">
            <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
              <CheckIcon v-if="hash.startsWith('0'.repeat(this.difficulty))" class="-ml-1 mr-2 h-5 w-5 text-green-600" />
              <XIcon v-else class="-ml-1 mr-2 h-5 w-5 text-red-600" />
              <span class="font-mono">{{ hash.slice(0, difficulty) }}</span>
            </span>
            <input :value="hash.substring(difficulty)" disabled type="text" name="hash" id="hash" class="flex-1 font-mono focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 truncate" />
          </div>
          <p class="mt-2 text-sm text-gray-500">First {{ difficulty }} characters need to be 0, <strong>mine</strong> to find the right nonce.</p>
        </div>
      </div>
    </div>

    <div class="p-4">
      <div class="flex justify-end">
        <button @click="mine()" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none">
          <TerminalIcon v-if="!mining" class="-ml-1 mr-2 h-5 w-5" />
          <DotsHorizontalIcon v-else class="animate-bounce -ml-1 mr-2 h-5 w-5" />
          Mine
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { TerminalIcon, DotsHorizontalIcon, CheckIcon, XIcon } from '@heroicons/vue/solid'

const shajs = require('sha.js')

export default {
  name: 'BlockchainBlock',
  components: {
    CheckIcon,
    TerminalIcon,
    DotsHorizontalIcon,
    XIcon
  },
  props: {
    block: {
      type: Number,
      required: true
    },
    prevHash: {
      type: String,
      required: false,
      default: '0'.repeat(64)
    },
    difficulty: {
      required: false,
      type: Number,
      default: 4
    }
  },
  data: () => ({
    nonce: 11316,
    data: '',
    hash: '',
    maxNonce: 10000000,
    mining: false
  }),
  methods: {
    mine () {
      this.mining = true

      setTimeout(() => {
        for (let n = 0; n < this.maxNonce; n++) {
          const data = `${this.block}${n}${this.data}${this.prevHash}`
          const hashData = shajs('sha256').update(data).digest('hex')

          if (hashData.startsWith('0'.repeat(this.difficulty))) {
            this.nonce = n
            this.hash = hashData
            this.mining = false
            break
          }
        }

        if (this.mining) {
          this.nonce = 0
          this.hash = '0'.repeat(64)
          this.mining = false
        }
      }, 10)
    }
  },
  watch: {
    data: {
      handler: function (value) {
        this.hash = shajs('sha256').update(`${this.block}${this.nonce}${value}${this.prevHash}`).digest('hex')
      },
      immediate: true
    },
    nonce: {
      handler: function (value) {
        this.hash = shajs('sha256').update(`${this.block}${value}${this.data}${this.prevHash}`).digest('hex')
      }
    },
    block: {
      handler: function (value) {
        this.hash = shajs('sha256').update(`${value}${this.nonce}${this.data}${this.prevHash}`).digest('hex')
      }
    },
    prevHash: {
      handler: function (value) {
        this.hash = shajs('sha256').update(`${this.block}${this.nonce}${this.data}${value}`).digest('hex')
      }
    },
    hash: {
      handler: function (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<style scoped>
.blockchain-block {
  min-width: calc(100vw - 64px - 32px) !important;
  scroll-snap-align: start;
}

@media screen and (min-width: 500px) {
  .blockchain-block {
    max-width: 380px !important;
    min-width: 380px !important;
  }
}
</style>
