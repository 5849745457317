<template>
  <div class="blockchain max-w-full flex flex-grow overflow-x-auto space-x-4 py-1 px-1">
    <BlockchainBlock v-model="blocks[index]" v-for="(block, index) in blocks" :key="index" :prevHash="blocks[index - 1]" :block="index + 1" :difficulty="difficulty" class="min-w-3/4 sm:min-w-1/2 md:min-w-0" />
    <button @click="blocks.push(blocks[blocks.length - 1])" type="button" class="blockchain-btn relative block border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      <PlusIcon class="mx-auto h-12 w-12 text-gray-400" />
      <span class="mt-2 block text-sm font-medium text-gray-900">
        Create a new block
      </span>
    </button>
  </div>
</template>

<script>
import { PlusIcon } from '@heroicons/vue/outline'

import BlockchainBlock from '@/components/BlockchainBlock.vue'

export default {
  name: 'Blockchain',
  components: {
    BlockchainBlock,
    PlusIcon
  },
  props: {
    difficulty: {
      required: false,
      type: Number,
      default: 4
    }
  },
  data: () => ({
    blocks: []
  }),
  mounted () {
    this.blocks = ['000015783b764259d382017d91a36d206d0600e2cbb3567748f46a33fe9297cf']
  }
}
</script>

<style scoped>
.blockchain {
  scroll-snap-type: x mandatory;
  scroll-padding: 16px;
}

.blockchain-btn {
  scroll-snap-align: start;
  min-width: 300px;
  max-width: 300px;
}
</style>
